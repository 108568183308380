import * as React from "react";
import { useRef, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/pages/homepage.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import CTA from "../components/Button/index";
import useModal from "../lib/useModal";
import { ModalContent } from "../components/Footer";
gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const heroImageRef = useRef(null);
  const aboutImageRef = useRef(null);
  const strategyImageRef = useRef(null);
  const ctaSectionRef = useRef(null);
  const { openModal } = useModal();

  useEffect(() => {
    if (heroImageRef.current) {
      gsap.fromTo(
        heroImageRef.current,
        { y: -300 },
        {
          y: 0,
          scrollTrigger: {
            trigger: "pcm-homepage__hero",
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }
    if (aboutImageRef.current) {
      gsap.fromTo(
        aboutImageRef.current,
        { y: -200 },
        {
          y: 0,
          scrollTrigger: {
            trigger: ctaSectionRef.current,
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }
    if (strategyImageRef.current) {
      gsap.fromTo(
        strategyImageRef.current,
        { y: -200 },
        {
          y: 0,
          scrollTrigger: {
            trigger: ctaSectionRef.current,
            scrub: true,
            start: "top bottom",
            end: "bottom top",
          },
        }
      );
    }

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);
  return (
    <div className="pcm-homepage">
      <section id="pcm-hero" className="pcm-homepage__hero">
        <div ref={heroImageRef} className="pcm-homepage__hero__image-wrapper">
          <StaticImage
            src="../images/homepage/hero1.jpg"
            alt="hero background"
            layout="fullWidth"
            quality={80}
            formats={["auto", "webp", "avif"]}
            style={{ height: "100%", width: "100%" }}
            loading="eager"
          />
        </div>
        <div className="pcm-homepage__hero__inner">
          <div className="pcm-homepage__hero__text">
            <h1>Invest with confidence</h1>
            <h3>
              Discover our innovative investment solutions designed to make
              investing less stressful while still providing competitive
              long-term returns.
            </h3>
          </div>
          <div className="pcm-homepage__hero__button">
            <CTA to="#pcm-homepage-ctas" theme="green">
              Learn more
            </CTA>
          </div>
        </div>
        <div
          onClick={() => openModal(ModalContent)}
          className="pcm-homepage__hero__disclaimer"
        >
          <div className="pcm-homepage__hero__disclaimer__inner">
            <h3>
              Preservation Capital Management does not generally make its
              strategy directly available to individual or retail investors.
            </h3>
            <h3>Click to read more</h3>
          </div>
        </div>
      </section>
      <section
        id="pcm-homepage-ctas"
        ref={ctaSectionRef}
        className="pcm-homepage__ctas"
      >
        <div className="pcm-homepage__ctas__inner">
          <div className="pcm-homepage__ctas__section">
            <div className="pcm-homepage__ctas__section__text">
              <h2>About Us</h2>
              <p>
                Discover Preservation Capital Management's unique approach to retirement
                investing, founded by industry veterans Jerry Chafkin and Matt
                Connors for their own investment needs. We specialize in
                low-cost, innovative strategies that prioritize the safety and
                growth of your savings. Our expertise, combined with Yayati
                Asset Management's quantitative insights, has crafted the
                Income+Growth Account, designed to mitigate risks and enhance
                returns. Learn how our tailored solutions can simplify and
                secure your retirement investment journey.
              </p>
              <div>
                <CTA to="/about" theme="green">
                  Learn about us
                </CTA>
              </div>
            </div>
            <div className="pcm-homepage__ctas__section__image__wrapper">
              <div
                ref={aboutImageRef}
                className="pcm-homepage__ctas__section__image"
              >
                <StaticImage
                  src="../images/homepage/aboutUs.jpg"
                  alt="about us"
                  layout="fullWidth"
                  quality={80}
                  formats={["auto", "webp", "avif"]}
                  style={{ height: "610px", width: "588px" }}
                />
              </div>
            </div>
          </div>
          <div className="pcm-homepage__ctas__section">
            <div className="pcm-homepage__ctas__section__text">
              <h2>Our Strategy</h2>
              <p>
                We design and deliver low-cost, innovative investment solutions
                for the clients of financial advisors that make investing and
                staying invested less stressful by structuring and communicating
                explicit loss limits while still providing investors with
                competitive long-term returns.
              </p>
              <div>
                <CTA to="/strategy" theme="green">
                  Learn our strategy
                </CTA>
              </div>
            </div>
            <div className="pcm-homepage__ctas__section__image__wrapper">
              <div
                ref={strategyImageRef}
                className="pcm-homepage__ctas__section__image"
              >
                <StaticImage
                  src="../images/homepage/ourStrategy.jpg"
                  alt="our strategy"
                  layout="fullWidth"
                  quality={80}
                  formats={["auto", "webp", "avif"]}
                  style={{ height: "610px", width: "588px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default React.memo(Home);
