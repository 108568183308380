import React, { useRef, useState } from "react";
import { Link } from "../links";
import { CTAProps } from "./types";
import classNames from "classnames";
import "./cta.scss";

const CTA: React.FC<CTAProps> = ({
  children,
  to,
  isOutbound = false,
  className,
  theme,
}) => {

  const ctaClasses = classNames("pcm-cta", {
    "pcm-cta--hollow": theme === "hollow",
    "pcm-cta--black": theme === "black",
    "pcm-cta--green": theme === "green",
  });

  return (
    <Link
      to={to}
      isOutbound={isOutbound}
      className={`${ctaClasses} ${className}`}
    >
      <p>
        {children}
      </p>
      <span className="pcm-cta__transition"></span>
      <div className="pcm-cta__arrow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 24 24"
        >
          <path
            fill="var(--white)"
            d="M4 11v2h12l-5.5 5.5l1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5L16 11z"
          />
          <path
            fill="var(--white)"
            d="M4 11v2h12l-5.5 5.5l1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5L16 11z"
          />
        </svg>
      </div>
    </Link>
  );
};

export default CTA;
